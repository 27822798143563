<template>
    <div class="mypage-modal-wrap">
        <div class="top_wrap">
            <div class="modal_title">{{ $t('password') }} {{ $t('change') }}</div>
            <div class="close_btn_wrap">
                <button class="close_modal" @click="modalClose()"></button>
            </div>
        </div>
        <div class="middle_wrap">
            <el-input class="el_info_input pw" type="password" maxlength="20"
                      :placeholder="$t('write_pwd')" v-model="oldPwd"
                      @input="parentChange('oldPwd',oldPwd)"
                      @keypress.enter="save()"/>
            <el-input class="el_info_input [w" type="password" maxlength="20"
                      :placeholder="$t('pwd')" v-model="pwd"
                      @input="parentChange('pwd',pwd)"
                      @keypress.enter="save()"/>
            <div class="validation" v-if="validation.hasError('pwd') && pwd.length > 0">
                {{ validation.firstError('pwd') }}
            </div>
            <el-input class="el_info_input pw" type="password" maxlength="20"
                      :placeholder="$t('confirm_pwd')" v-model="pwdChk"
                      @input="parentChange('pwdChk',pwdChk)"
                      @keypress.enter="save()"/>
            <!--       비밀번호 체크 -->
            <div class="validation pw" v-if="validation.hasError('pwdChk') && pwdChk.length > 0">
                {{ validation.firstError('pwdChk') }}
            </div>
            <div class="validation pw" v-if="checkPwd">
                {{ $t('pwdErrorMsg1') }}
            </div>
            <div class="confirm_btn_wrap">
                <button class="btn save" @click="save()">{{ $t('save') }}</button>
                <button class="btn cancel" @click="modalClose()">{{ $t('cancel') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import memberValidator from "@/mixins/validators/memberValidator";

export default {
    name: "MemberWritePwdLayout",
    mixins: [alertMixins, memberValidator],
    components: {},
    inject: ['memberSetData'],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            checkPwd: false
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        EventBus.$on('checkData', this.checkData);
    },
    beforeDestroy() {
        EventBus.$off('checkData');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        modalClose() {
            this.oldPwd = '';
            this.pwd = '';
            this.pwdChk = '';
            this.$modal.hide('mypage-pw-modal')
        },
        save() {
            EventBus.$emit('updateMember')
        },
        checkData(type) {
            this.checkPwd = type
        },
        parentChange(type, value) {
            this[type] = value;
            this.memberSetData(type, value);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>